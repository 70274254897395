<template>
  <b-row>
    <b-col>
      <h5>{{ $t("vehicle-event-history.filter.caption") }}</h5>
      <b-row class="mb-2">
        <b-col>
          <v-select
            :value="filter.role"
            :options="roles"
            :reduce="x => x.key"
            :placeholder="$t('vehicle-event-history.filter.by-role')"
            @input="onChangeFilter($event, 'role')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <v-select
            :value="filter.user"
            :options="$store.getters['vehicleEvents/users']"
            :reduce="x => x.key"
            :placeholder="$t('vehicle-event-history.filter.by-user')"
            @input="onChangeFilter($event, 'user')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <v-select
            :value="filter.event"
            :options="events"
            :reduce="x => x.key"
            :placeholder="$t('vehicle-event-history.filter.by-event-type')"
            @input="onChangeFilter($event, 'event')"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { ADMINISTRATOR, MANAGER, DRIVER } from "@/const/user-role.js";
import { CREATE, CHANGE_TYPE, INCIDENT } from "@/const/vehicle-events.js";
import { LOAD, ADDITIONAL_LOAD, ACCEPTANCE, CHECKOUT, AUTOMATIC_CHECKOUT, UNLOAD } from "@/const/vehicle-load-types.js";

export default {
  computed: {
    filter() {
      return this.$store.getters["vehicleEvents/filter"];
    },
    roles() {
      return [
        { key: ADMINISTRATOR, label: this.$t("roles.administrator") },
        { key: MANAGER, label: this.$t("roles.manager") },
        { key: DRIVER, label: this.$t("roles.driver") }
      ];
    },
    events() {
      return [
        { key: CREATE, label: this.$t("vehicle-event-history.event.vehicle-create") },
        { key: CHANGE_TYPE, label: this.$t("vehicle-event-history.event.vehicle-change-type") },
        { key: INCIDENT, label: this.$t("vehicle-event-history.event.incident") },
        { key: LOAD, label: this.$t("vehicle-event-history.event.load") },
        { key: UNLOAD, label: this.$t("vehicle-event-history.event.unload") },
        { key: ADDITIONAL_LOAD, label: this.$t("vehicle-event-history.event.additional-load") },
        { key: ACCEPTANCE, label: this.$t("vehicle-event-history.event.acceptance") },
        { key: CHECKOUT, label: this.$t("vehicle-event-history.event.checkout") },
        { key: AUTOMATIC_CHECKOUT, label: this.$t("vehicle-event-history.event.automatic-checkout") }
      ];
    }
  },
  methods: {
    onChangeFilter(value, field) {
      this.$store.commit("vehicleEvents/setFilterState", { value, field });
    }
  },
  mounted() {
    this.$store.dispatch("vehicleEvents/loadUsers").then(resp => {
      this.$store.commit(
        "vehicleEvents/setUsersState",
        resp.data.map(x => {
          return { key: x.userName, label: x.userName, role: x.role };
        })
      );
    });
  }
};
</script>
