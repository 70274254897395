<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <vehicle-events-searcher />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <vehicle-events-filter />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <vehicle-events-search-result />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VehicleEventsFilter from "@/components/vehicle/event/VehicleEventsFilter.vue";
import VehicleEventsSearcher from "@/components/vehicle/event/VehicleEventsSearcher.vue";
import VehicleEventsSearchResult from "@/components/vehicle/event/VehicleEventsSearchResult.vue";

export default {
  components: {
    VehicleEventsFilter,
    VehicleEventsSearcher,
    VehicleEventsSearchResult
  }
};
</script>
