<template>
  <div>
    <h5>{{ $t("vehicle-event-history.events-search-period") }}</h5>
    <b-row class="my-2">
      <b-col>
        <b-form-datepicker
          v-model="searchParams.from"
          :locale="$i18n.locale"
          :placeholder="$t('vehicle-event-history.start-search-period')"
          :state="errorStartDate"
          @input="onChangeEventsPeriod"
        ></b-form-datepicker>
      </b-col>
      <b-col>
        <b-form-datepicker
          v-model="searchParams.to"
          :locale="$i18n.locale"
          :placeholder="$t('vehicle-event-history.end-search-period')"
          :state="true"
          @input="onChangeEventsPeriod"
        ></b-form-datepicker>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-input
          :placeholder="$t('vehicle-event-history.vehicle-registration-number-or-vin')"
          v-model="vehicleSearchValue"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-button @click="onFindVehicles">{{ $t("vehicle-event-history.search-btn") }}</b-button>
      </b-col>
    </b-row>
    <b-modal
      id="vehicle-list"
      size="xl"
      :ok-disabled="isNaN(searchParams.vehicleId)"
      @ok="onApplySelectedVehicle"
      ok-only
    >
      <template v-slot:default>
        <b-table
          :fields="fields"
          :items="vehicles"
          @row-selected="onSelectVehicle"
          selectable
          select-mode="single"
          responsive
        >
          <template v-slot:cell(usageStartDate)="row">{{ convertDateToString(row.item.usageStartDate) }}</template>
          <template v-slot:cell(saleDate)="row">{{ convertDateToString(row.item.saleDate) }}</template>
        </b-table>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/services/api.js";

export default {
  data() {
    return {
      searchParams: {
        vehicleId: NaN,
        from: null,
        to: new Date().toISOString().slice(0, 10)
      },
      vehicleSearchValue: "",
      vehicles: []
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "registrationNumber",
          label: this.$t("vehicle-event-history.select-vehicle-modal.registration-number"),
          sortable: true
        },
        { key: "vin", label: this.$t("vehicle-event-history.select-vehicle-modal.vin"), sortable: true },
        { key: "model", label: this.$t("vehicle-event-history.select-vehicle-modal.model"), sortable: true },
        { key: "brand", label: this.$t("vehicle-event-history.select-vehicle-modal.brand"), sortable: true },
        {
          key: "usageStartDate",
          label: this.$t("vehicle-event-history.select-vehicle-modal.usageStartDate"),
          sortable: true
        },
        { key: "saleDate", label: this.$t("vehicle-event-history.select-vehicle-modal.saleDate"), sortable: true }
      ];
    },
    errorStartDate() {
      return !this.searchParams.from || new Date(this.searchParams.from) <= new Date(this.searchParams.to);
    }
  },
  methods: {
    onFindVehicles() {
      this.$store.commit("vehicleEvents/clear");
      this.$store.commit("vehicleEvents/setSelectedVehicle", {});
      if (!this.vehicleSearchValue) return;
      api.searchVehicles(this.vehicleSearchValue).then(resp => {
        if (resp.data.length > 1) {
          this.vehicles = resp.data;
          this.$bvModal.show("vehicle-list");
        } else if (resp.data.length === 1) {
          this.searchParams.vehicleId = resp.data[0].id;
          this.$store.commit("vehicleEvents/setSearchParams", this.searchParams);
          this.$store.commit("vehicleEvents/setSelectedVehicle", resp.data[0]);
          this.$store.dispatch("vehicleEvents/loadEvents");
        }
      });
    },
    onSelectVehicle(rows) {
      this.searchParams.vehicleId = rows[0].id;
      this.$store.commit("vehicleEvents/setSearchParams", this.searchParams);
    },
    onApplySelectedVehicle() {
      this.$store.commit(
        "vehicleEvents/setSelectedVehicle",
        this.vehicles.find(x => x.id == this.searchParams.vehicleId)
      );
      this.$store.dispatch("vehicleEvents/loadEvents");
    },
    onChangeEventsPeriod() {
      this.$store.commit("vehicleEvents/clear");
      this.$store.commit("vehicleEvents/setSearchParams", this.searchParams);
      if (!isNaN(this.searchParams.vehicleId)) {
        this.$store.dispatch("vehicleEvents/loadEvents");
      }
    },
    convertDateToString(date) {
      if (date == null) return "";
      return this.$d(new Date(date));
    }
  },
  beforeDestroy() {
    this.$store.commit("vehicleEvents/clear");
  }
};
</script>
