<template>
  <div v-if="events.length > 0">
    <h5 class="mt-2">
      {{ $t("vehicle-event-history.events-search-result", { registrationNumber: vehicle.registrationNumber }) }}
    </h5>
    <ul>
      <li v-for="(event, i) in events" :key="i">
        [{{ $d(Date.parse(event.createdAt), "log") }}]
        <span v-if="isCreateVehicleEvent(event.type)">
          {{
            $t("vehicle-event-history.create-vehicle-event", {
              userName: event.createdBy.userName,
              category: vehicle.category.title[$i18n.locale],
              registrationNumber: vehicle.registrationNumber
            })
          }}
        </span>
        <span v-if="isLoadEvent(event.type)">
          <i18n path="vehicle-event-history.load-event">
            <template v-slot:userName>{{ event.createdBy.userName }}</template>
            <template v-slot:link>
              <b-link @click="showVehicleLoadViewer(event.eventData.loadId)">
                {{ $t("vehicle-event-history.vehicle-load-link") }}
              </b-link>
            </template>
          </i18n>
        </span>
        <span v-if="isAdditionalLoadEvent(event.type)">
          <i18n path="vehicle-event-history.additional-load-event">
            <template v-slot:userName>{{ event.createdBy.userName }}</template>
            <template v-slot:link>
              <b-link @click="showVehicleLoadViewer(event.eventData.loadId)">
                {{ $t("vehicle-event-history.vehicle-load-link") }}
              </b-link>
            </template>
          </i18n>
        </span>
        <span v-if="isUnloadEvent(event.type)">
          {{ $t("vehicle-event-history.unload-event", { userName: event.createdBy.userName }) }}
        </span>
        <span v-if="isAcceptanceEvent(event.type)">
          <i18n path="vehicle-event-history.acceptance-event">
            <template v-slot:userName>{{ event.createdBy.userName }}</template>
            <template v-slot:link>
              <b-link @click="showVehicleLoadViewer(event.eventData.loadId)">
                {{ $t("vehicle-event-history.vehicle-load-link") }}
              </b-link>
            </template>
          </i18n>
        </span>
        <span v-if="isCheckoutEvent(event.type)">
          <i18n path="vehicle-event-history.checkout-event">
            <template v-slot:userName>{{ event.createdBy.userName }}</template>
            <template v-slot:link>
              <b-link @click="showVehicleLoadViewer(event.eventData.loadId)">
                {{ $t("vehicle-event-history.vehicle-load-link") }}
              </b-link>
            </template>
          </i18n>
        </span>
        <span v-if="isAutomaticCheckout(event.type)">
          <i18n path="vehicle-event-history.automatic-checkout-event">
            <template v-slot:userName>{{ event.createdBy.userName }}</template>
            <template v-slot:link>
              <b-link @click="showVehicleLoadViewer(event.eventData.loadId)">
                {{ $t("vehicle-event-history.vehicle-load-link") }}
              </b-link>
            </template>
          </i18n>
        </span>
        <span v-if="isChangeTypeEvent(event.type)">
          {{
            $t("vehicle-event-history.change-type-event", {
              userName: event.createdBy.userName,
              oldType: event.eventData.oldType[$i18n.locale],
              newType: event.eventData.newType[$i18n.locale]
            })
          }}
        </span>
        <span v-if="isIncidentEvent(event.type)">
          <i18n path="vehicle-event-history.incident-event">
            <template v-slot:userName>{{ event.createdBy.userName }}</template>
            <template v-slot:link>
              <router-link :to="{ name: 'incident', params: { incidentId: event.eventData.incident.id } }">
                {{ $t("vehicle-event-history.incident-link") }}
              </router-link>
            </template>
          </i18n>
        </span>
      </li>
    </ul>
    <b-modal
      id="load-items-viewer"
      :title="$t('vehicle-event-history.load-items-viewer.title')"
      @hidden="loadItems = []"
      ok-only
    >
      <template v-slot:default>
        <b-table :fields="loadItemFields" :items="loadItems">
          <template v-slot:cell(title)="row">{{ row.item.title[$i18n.locale] }}</template>
          <template v-slot:cell(serialNumbers)="row">
            <serial-numbers v-if="row.value.length > 0" :value="row.value" disabled></serial-numbers>
          </template>
        </b-table>
      </template>
    </b-modal>
  </div>
  <div v-else>
    <h5 class="mt-2">{{ $t("vehicle-event-history.empty-events-search-result") }}</h5>
  </div>
</template>

<script>
import SerialNumbers from "@/components/shared/SerialNumbers.vue";

import api from "@/services/api.js";

import { CREATE, CHANGE_TYPE, INCIDENT } from "@/const/vehicle-events.js";
import { LOAD, ADDITIONAL_LOAD, ACCEPTANCE, CHECKOUT, AUTOMATIC_CHECKOUT, UNLOAD } from "@/const/vehicle-load-types.js";

export default {
  components: {
    SerialNumbers
  },
  data() {
    return {
      loadItems: []
    };
  },
  computed: {
    vehicle() {
      return this.$store.getters["vehicleEvents/vehicle"];
    },
    events() {
      return this.$store.getters["vehicleEvents/events"];
    },
    loadItemFields() {
      return [
        { key: "title", label: this.$t("vehicle-event-history.load-items-viewer.fields.title") },
        { key: "count", label: this.$t("vehicle-event-history.load-items-viewer.fields.count") },
        { key: "serialNumbers", label: this.$t("vehicle-event-history.load-items-viewer.fields.serialNumbers") }
      ];
    }
  },
  methods: {
    isLoadEvent(type) {
      return type === LOAD;
    },
    isCreateVehicleEvent(type) {
      return type == CREATE;
    },
    isAdditionalLoadEvent(type) {
      return type === ADDITIONAL_LOAD;
    },
    isUnloadEvent(type) {
      return type === UNLOAD;
    },
    isAcceptanceEvent(type) {
      return type === ACCEPTANCE;
    },
    isCheckoutEvent(type) {
      return type === CHECKOUT;
    },
    isAutomaticCheckout(type) {
      return type === AUTOMATIC_CHECKOUT;
    },
    isChangeTypeEvent(type) {
      return type === CHANGE_TYPE;
    },
    isIncidentEvent(type) {
      return type === INCIDENT;
    },
    showVehicleLoadViewer(loadId) {
      api.getLoadItemsByLoadId(loadId).then(resp => {
        Promise.all(resp.data.map(x => api.getGoodsById(x.goodsId))).then(values => {
          this.loadItems = values.map(x => {
            return {
              ...resp.data.find(y => y.goodsId === x.data.id),
              title: x.data.title
            };
          });
          this.$bvModal.show("load-items-viewer");
        });
      });
    },
    hasIncidentDescription(data) {
      return data.incident && data.incident.description && data.incident.description.length > 0;
    }
  }
};
</script>
